.noDataTitle {
  font-size: 14px;
  font-weight: 600;
  color: #4b5153;
  text-align-last: left;
}

.NoDataText {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.NoDataText h4{
    font-size: 33px;
    font-weight: 700;
}
